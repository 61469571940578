import { motion, AnimatePresence } from "framer-motion";
import { useState } from "react";
import { ChevronDown, Sparkle } from "lucide-react";
import { Robot, Spinner } from "@phosphor-icons/react/dist/ssr";
import { toast } from 'sonner'
import ReactMarkdown from 'react-markdown';

export default function AskCreate({ user }: { user: any }) {
    const [isAiOpen, setIsAiOpen] = useState(false);
    const [aiPrompt, setAiPrompt] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [response, setResponse] = useState<string | null>(null);

    const suggestedPrompts = [
        "What do I have planned today?",
        "Write me a tweet about what I've been thinking about recently",
        "What blog posts was I thinking about writing?"
    ];

    const handleAskAI = async () => {
        if (!aiPrompt.trim()) return;
        
        if (!user || !user?.premium) {
            toast.error("This feature requires a premium account. Please upgrade to continue.", {
                duration: 4000,
                icon: '⭐️',
            });
            return;
        }
        
        setIsLoading(true);
        try {
            const response = await fetch('/api/ask', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    prompt: aiPrompt,
                    userId: user.id,
                }),
            });

            if (!response.ok) {
                throw new Error('Failed to get AI response');
            }

            const data = await response.json();
            setResponse(data.response);
        } catch (error) {
            console.error('Failed to get AI response:', error);
            setResponse('Sorry, I encountered an error while processing your request.');
        } finally {
            setIsLoading(false);
        }
    };


    return (
        <motion.div
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3, delay: 0.1 }}
            className="col-span-3"
          >
            <motion.div 
                className="flex flex-col mx-auto w-full max-w-2xl gap-2 p-2 pb-3 border-b border-neutral-200"
                layout
            >
                <button 
                    data-ph-capture-attribute-user-action="Toggle Ask / Create"
                    onClick={() => setIsAiOpen(prev => !prev)}
                    className="flex flex-row justify-between items-center w-full hover:bg-neutral-50 rounded-lg p-2 transition-colors"
                >
                    <div className='flex space-x-2 flex-col items-start'>
                        <div className="flex flex-row gap-2 items-center">
                            <Sparkle className="w-5 h-5 text-neutral-400" />
                            <h2 className="text-xl font-semibold font-aleo">Ask / Create</h2>
                        </div>
                        <p className="text-xs text-neutral-500 mt-2">Generate content or ask about your tasks and notes</p>
                    </div>
                    <div className="text-neutral-400">
                        <ChevronDown 
                            className={`w-5 h-5 transition-transform duration-200 ${isAiOpen ? 'rotate-180' : ''}`}
                        />
                    </div>
                </button>

                <AnimatePresence>
                    {isAiOpen && (
                        <motion.div
                            initial={{ height: 0, opacity: 0 }}
                            animate={{ height: 'auto', opacity: 1 }}
                            exit={{ height: 0, opacity: 0 }}
                            transition={{ duration: 0.2 }}
                            className="overflow-hidden"
                        >
                            <div className=" rounded-lg p-4 space-y-4 bg-white">
                                <textarea
                                    className="w-full text-sm min-h-[100px] p-3 rounded-md border focus:outline-none focus:ring-2 focus:ring-orange-400 resize-none"
                                    placeholder="Ask about your tasks and notes. For example: 'What tasks are due this week?' or 'Find notes about project planning'"
                                    value={aiPrompt}
                                    onChange={(e) => setAiPrompt(e.target.value)}
                                />

                                <div className="text-xs text-neutral-500 px-2">Suggestions</div>
                                <div className="flex flex-wrap gap-2 pl-2">
                                    {suggestedPrompts.map((prompt, index) => (
                                        <button
                                            data-ph-capture-attribute-user-action="Suggested Prompt Clicked"
                                            pirsch-meta-Prompt={prompt}
                                            key={index}
                                            onClick={() => setAiPrompt(prompt)}
                                            className="text-xs text-orange-600 bg-white border-orange-600 border px-2 py-1 rounded-full hover:bg-neutral-50 transition-colors"
                                        >
                                            {prompt}
                                        </button>
                                    ))}
                                </div>

                                <div className="flex justify-end">
                                    <button
                                        data-ph-capture-attribute-user-action="Ask AI Executed"
                                        pirsch-meta-Prompt={aiPrompt}
                                        className="bg-orange-500 text-sm text-white disabled:opacity-85 px-4 py-2 rounded-md hover:bg-orange-600 transition-colors disabled:cursor-not-allowed flex items-center gap-2"
                                        disabled={!aiPrompt.trim() || isLoading}
                                        onClick={handleAskAI}
                                    >
                                        {isLoading ? (
                                            <>
                                                <span className="animate-spin"><Spinner className="w-4 h-4" /></span>
                                                Processing...
                                            </>
                                        ) : (
                                            'Ask AI'
                                        )}
                                    </button>
                                </div>
                                
                                {response && (
                                    <div className="bg-white rounded-xl border p-4 border-gray-950/[.1] hover:bg-gray-950/[.05]">
                                        <div className="flex flex-row items-center gap-2">
                                            <Robot className="w-6 h-6 text-neutral-400" />
                                            <div className="flex flex-col">
                                                <div className="text-sm font-medium">mmbl says...</div>
                                            </div>
                                        </div>
                                        <div className="mt-2 text-sm prose prose-sm max-w-none">
                                            <ReactMarkdown>{response}</ReactMarkdown>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </motion.div>
                    )}
                </AnimatePresence>
            </motion.div>
          </motion.div> 
    );
}