"use client"

import React, { useState, useEffect } from "react"
import { motion } from "framer-motion"
import { GraduationCap, Briefcase, Mic, UserCircle2, Laptop, Circle } from "lucide-react"
import { Tabs, TabsList, TabsTrigger } from "./ui/tabs"
import { hashStringToColor, getDarkerColor, hexToRgb } from '~/lib/utils'

interface UseCase {
  icon: React.ElementType
  title: string
  points: [string, string][]
  tags: string[]
}

const useCases: UseCase[] = [
{
    icon: GraduationCap,
    title: "Students",
    points: [
      ["No more frantic scribbling", "Speak your lecture notes or assignment ideas; let mmbl transcribe and tag them for easy reference"],
      ["Never miss a due date", "Quickly record \"Research paper due next Friday\" and mmbl sets the reminder in your calendar"],
    ],
    tags: ["lectures", "projects", "meetings"]
  },
  {
    icon: Briefcase,
    title: "Entrepreneurs",
    points: [
      ["Capture your next big idea", "Brain dump on the go, and mmbl automatically sorts it under tags like \"marketing,\" \"pitch deck,\" etc"],
      ["Instant to-dos", "Voice a task, and mmbl detects it, sets a deadline, and syncs to your calendar—no busywork"],
    ],
    tags: ["work", "pitches", "marketing"]
  },
  {
    icon: Mic,
    title: "Podcasters",
    points: [
      ["Show notes, handled", "Ramble your topic ideas or guest questions. mmbl organises them so you can focus on the mic"],
      ["Episode scheduling", "Mention \"Interview next Tuesday at 10am,\" and mmbl auto-creates the event in your calendar"],
    ],
    tags: ["content", "show notes"]
  },
  {
    icon: Laptop,
    title: "Freelancers",
    points: [
      ["Client briefs on the fly", "Record feedback or project briefs during calls, and let mmbl sort everything for quick access later"],
      ["Stay on top of multiple gigs", "Voice your to-dos, let mmbl handle tagging by client or project, and stay organised effortlessly"],
    ],
    tags: ["clients", "projects", "tasks"]
  },
  {
    icon: UserCircle2,
    title: "Anyone",
    points: [
      ["Grocery lists made easy", "Simply say \"Remember to buy milk and eggs,\" and mmbl organises your shopping needs"],
      ["Stay fit and on schedule", "Record \"Gym session at 6pm,\" and mmbl sets a reminder to keep you on track"],
    ],
    tags: ["groceries", "fitness", "reminders"]
  },
]

export function UseCases() {
  const [activeCase, setActiveCase] = useState(0)
  const [isHovered, setIsHovered] = useState(false)

  // Auto-rotate tabs every 5 seconds unless hovered
  useEffect(() => {
    if (isHovered) return

    const interval = setInterval(() => {
      setActiveCase((current) => (current + 1) % useCases.length)
    }, 10000)

    return () => clearInterval(interval)
  }, [isHovered])

  return (
    <section
      className=""
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className="max-w-3xl mx-auto">
        <h2 className="text-3xl md:text-xl mb-6 font-bold text-center">Who should use mmbl?</h2>

        <div className="space-y-4">
          {/* Navigation Tabs */}
          <Tabs value={activeCase.toString()} onValueChange={(v) => setActiveCase(Number.parseInt(v))}>
            <TabsList className="w-full h-auto flex flex-wrap justify-center gap-2 ">
              {useCases.map((useCase, index) => {
                const Icon = useCase.icon
                return (
                  <TabsTrigger
                    key={useCase.title}
                    value={index.toString()}
                    className={
                      "flex items-center gap-2 px-2 md:px-4 py-2 rounded-lg data-[state=active]:border data-[state=active]:border-primary data-[state=active]:text-primary-foreground transition-all duration-300"
                    }
                  >
                    <Icon className="w-4 h-4" />
                    <span>For {useCase.title}</span>
                  </TabsTrigger>
                )
              })}
            </TabsList>
          </Tabs>

          {/* Content Area */}
          <div className="relative">
            <motion.div
              key={activeCase}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3 }}
              className="relative w-full cursor-pointer overflow-hidden rounded-xl border p-4 border-gray-950/[.1] bg-gray-950/[.01]"
            >
              <div className="flex items-center gap-4 mb-4">
                <h3 className="text-lg font-medium">{useCases[activeCase].title}</h3>
              </div>
              <ul className="space-y-2 mb-4">
                {useCases[activeCase].points.map((point, index) => (
                  <li key={index} className="flex items-start gap-4 list-disc">
                    <span className="flex-1 text-sm text-gray-600 dark:text-white/70 leading-relaxed">
                      <strong>{point[0]}:</strong> {point[1]}
                    </span>
                  </li>
                ))}
              </ul>
              <div className="flex flex-wrap gap-2">
                {useCases[activeCase].tags.map((tag) => {
                  const bgColor = hashStringToColor(tag);
                  const textColor = getDarkerColor(bgColor);
                  const rgb = hexToRgb(bgColor);
                  return (
                    <span
                      key={tag}
                      className="capitalize items-center flex flex-row rounded-full px-2 py-0.5 text-xs font-ubuntu-mono"
                      style={{ 
                        backgroundColor: bgColor, 
                        color: textColor,
                        '--tw-ring-color': `rgb(${rgb[0]}, ${rgb[1]}, ${rgb[2]}, 0.5)`
                      }}
                    >
                      <Circle className="w-2 h-2 inline mr-1" style={{fill: textColor}} />
                      {tag}
                    </span>
                  );
                })}
              </div>
            </motion.div>
          </div>
        </div>
      </div>
    </section>
  )
}

